<template>
  <div class="h-100 text-typography-primary">
    <h1 class="mb-4">
      {{ supplierName || $t('document.modal.digitalDocument.documentKeys.supplier') }}
    </h1>
    <h3 class="mb-4">
      <div>
        {{ $t('document.modal.digitalDocument.documentKeys.documentTypes.document') }}
        {{ $t('document.exports.schema.fields.documentNumber') }}
        {{ document.documentNumber || '-' }}
      </div>
      <div v-if="structureParams.orderReference">
        {{ $t('document.exports.schema.fields.orderReference') }}
        {{ document.orderReference || '-' }}
      </div>
    </h3>
    <div class="d-flex mb-4">
      <div>
        <p>
          {{ $t('document.exports.schema.fields.issueDate') }}
        </p>
        <p v-if="structureParams.allocationNumber">
          {{ $t('document.exports.schema.fields.allocationNumber') }}
        </p>
        <p v-if="structureParams.paymentDueDate">
          {{ $t('document.exports.schema.fields.paymentDueDate') }}
        </p>
        <p v-if="structureParams.deliveryDate">
          {{ $t('document.exports.schema.fields.deliveryDate') }}
        </p>
      </div>
      <div class="mx-4">
        <p>{{ formatDate(document.issueDate) }}</p>

        <p v-if="structureParams.allocationNumber">
          {{ document.allocationNumber }}
        </p>

        <p v-if="structureParams.paymentDueDate">
          {{ formatDate(document.paymentDueDate) }}
        </p>
        <p v-if="structureParams.deliveryDate">
          {{ formatDate(document.deliveryDate) }}
        </p>
      </div>
    </div>
    <div v-if="structureParams.items" class="mb-7">
      <h3 class="mb-2">{{ $t('document.exports.schema.fields.items') }}</h3>
      <Table
        :columns="itemColumns"
        :data="itemsTableRows"
        :custom-class="itemsTableRows.length === 0 ? 'text-disabled' : ''"
        :hover="false"
        rounded
        border
      >
        <template #cell-sku="{ rowData: { sku } }">
          <p class="direction-ltr">{{ sku }}</p>
        </template>
      </Table>
    </div>
    <div v-if="document.paymentMethod" class="mb-7">
      <h3 class="mb-2">{{ $t('document.modal.digitalDocument.paymentDetails') }}</h3>
      <Table
        :columns="paymentDetailColumns"
        :data="paymentDetailRows"
        :custom-class="paymentDetailRows.length === 0 ? 'text-disabled' : ''"
        :hover="false"
        rounded
        border
      />
    </div>
    <div v-if="structureParams.references" class="mb-7">
      <h3 class="mb-2">{{ $t('document.exports.schema.fields.references') }}</h3>
      <Table
        :columns="referenceColumns"
        :data="referenceTableRows"
        :custom-class="referenceTableRows.length === 0 ? 'text-disabled' : ''"
        :hover="false"
        rounded
        border
      />
    </div>
    <div v-if="structureParams.generalCharges" class="mb-7">
      <h3 class="mb-2">{{ $t('document.exports.schema.fields.generalCharges') }}</h3>
      <Table
        :columns="generalChargesColumns"
        :data="generalChargesTableRows"
        :custom-class="generalChargesTableRows.length === 0 ? 'text-disabled' : ''"
        :hover="false"
        rounded
        border
      />
    </div>
    <div class="d-flex justify-content-end">
      <div>
        <p v-if="structureParams.openingBalance">{{ $t('document.exports.schema.fields.openingBalance') }}:</p>
        <p v-if="structureParams.netAmount">{{ $t('document.exports.schema.fields.netAmount') }}:</p>
        <p v-if="structureParams.discountRate">{{ $t('document.exports.schema.fields.discountRate') }}:</p>
        <p v-if="structureParams.discountAmount">{{ $t('document.exports.schema.fields.discountAmount') }}:</p>
        <p v-if="structureParams.rounding">{{ $t('document.exports.schema.fields.rounding') }}:</p>
        <p v-if="structureParams.taxableAmount">{{ $t('document.exports.schema.fields.taxableAmount') }}:</p>
        <p v-if="structureParams.nonTaxableAmount">{{ $t('document.exports.schema.fields.nonTaxableAmount') }}:</p>
        <p v-if="structureParams.taxRate">{{ $t('document.exports.schema.fields.taxRate') }}:</p>
        <p v-if="structureParams.taxAmount">{{ $t('document.exports.schema.fields.taxAmount') }}:</p>
        <p v-if="structureParams.totalAmount">{{ $t('document.exports.schema.fields.totalAmount') }}:</p>
        <p v-if="structureParams.amountDue">{{ $t('document.exports.schema.fields.amountDue') }}:</p>
      </div>
      <div class="mx-7">
        <p v-if="structureParams.openingBalance">
          {{ formatMoney(document.openingBalance) }}
        </p>
        <p v-if="structureParams.netAmount">
          {{ formatMoney(document.netAmount) }}
        </p>
        <p v-if="structureParams.discountRate">
          {{ formatPercent(document.discountRate) }}
        </p>
        <p v-if="structureParams.discountAmount">
          {{ formatMoney(document.discountAmount) }}
        </p>
        <p v-if="structureParams.rounding">
          {{ formatMoney(document.rounding) }}
        </p>
        <p v-if="structureParams.taxableAmount">
          {{ formatMoney(document.taxableAmount) }}
        </p>
        <p v-if="structureParams.nonTaxableAmount">
          {{ formatMoney(document.nonTaxableAmount) }}
        </p>
        <p v-if="structureParams.taxRate">
          {{ formatPercent(document.taxRate) }}
        </p>
        <p v-if="structureParams.taxAmount">
          {{ formatMoney(document.taxAmount) }}
        </p>
        <p v-if="structureParams.totalAmount">
          {{ formatMoney(document.totalAmount) }}
        </p>
        <p v-if="structureParams.amountDue">
          {{ formatMoney(document.amountDue) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { isNil, reject, isEmpty } from 'ramda';
import { percent } from '@/locale/numberConfig';
import { options } from '@/locale/dateConfig';
import { Table } from '@/modules/core';
import { PAYMENT_METHOD_TYPE } from '@/modules/payment/types';
import { constants } from '@/modules/purchase-management';
import { useCurrency } from '@/modules/document/compositions';

const DIGITAL_ITEMS_TABLE_HEADERS = {
  REFERENCE: 'reference',
  SKU: 'sku',
  GTIN: 'gtin',
  NAME: 'name',
  PRICE: 'price',
  NET_PRICE: 'netPrice',
  QUANTITY: 'quantity',
  QUANTITY_IN_PACKAGE: 'quantityInPackage',
  PACKAGE_QUANTITY: 'packageQuantity',
  DISCOUNT_RATE: 'discountRate',
  DISCOUNT_AMOUNT: 'discountAmount',
  TOTAL_DISCOUNT: 'totalDiscount',
  TOTAL_TAX: 'totalTax',
  TOTAL_DEPOSITS: 'totalDeposits',
  TOTAL_PACKAGES: 'totalPackages',
  TOTAL_AMOUNT: 'totalAmount',
};

const DIGITAL_PAYMENT_DETAILS_TABLE_HEADERS = {
  PAYMENT_DATE: 'paymentDate',
  PAID_AMOUNT: 'paidAmount',
  PAYMENT_METHOD: 'paymentMethod',
  DETAILS: 'details',
};

const DIGITAL_REFERENCES_TABLE_HEADERS = {
  DOCUMENT_NUMBER: 'documentNumber',
  ISSUE_DATE: 'issueDate',
  PAYMENT_DUE_DATE: 'paymentDueDate',
  NET_AMOUNT: 'netAmount',
  TOTAL_AMOUNT: 'totalAmount',
  DEBIT_AMOUNT: 'debitAmount',
  CREDIT_AMOUNT: 'creditAmount',
  BALANCE: 'balance',
};

const DIGITAL_GENERAL_CHARGES_TABLE_HEADERS = {
  NAME: 'name',
  AMOUNT: 'amount',
};

const fieldsType = {
  STRING: 'string',
  NUMBER: 'number',
  MONEY: 'money',
  PERCENT: 'percent',
  DATE: 'date',
};

const fieldsTypeMap = {
  [DIGITAL_ITEMS_TABLE_HEADERS.REFERENCE]: fieldsType.STRING,
  [DIGITAL_ITEMS_TABLE_HEADERS.SKU]: fieldsType.STRING,
  [DIGITAL_ITEMS_TABLE_HEADERS.GTIN]: fieldsType.STRING,
  [DIGITAL_ITEMS_TABLE_HEADERS.NAME]: fieldsType.STRING,
  [DIGITAL_ITEMS_TABLE_HEADERS.PRICE]: fieldsType.MONEY,
  [DIGITAL_ITEMS_TABLE_HEADERS.NET_PRICE]: fieldsType.MONEY,
  [DIGITAL_ITEMS_TABLE_HEADERS.QUANTITY]: fieldsType.NUMBER,
  [DIGITAL_ITEMS_TABLE_HEADERS.QUANTITY_IN_PACKAGE]: fieldsType.NUMBER,
  [DIGITAL_ITEMS_TABLE_HEADERS.PACKAGE_QUANTITY]: fieldsType.NUMBER,
  [DIGITAL_ITEMS_TABLE_HEADERS.DISCOUNT_RATE]: fieldsType.PERCENT,
  [DIGITAL_ITEMS_TABLE_HEADERS.DISCOUNT_AMOUNT]: fieldsType.MONEY,
  [DIGITAL_ITEMS_TABLE_HEADERS.TOTAL_DISCOUNT]: fieldsType.MONEY,
  [DIGITAL_ITEMS_TABLE_HEADERS.TOTAL_TAX]: fieldsType.MONEY,
  [DIGITAL_ITEMS_TABLE_HEADERS.TOTAL_DEPOSITS]: fieldsType.MONEY,
  [DIGITAL_ITEMS_TABLE_HEADERS.TOTAL_PACKAGES]: fieldsType.MONEY,
  [DIGITAL_ITEMS_TABLE_HEADERS.TOTAL_AMOUNT]: fieldsType.MONEY,
  [DIGITAL_REFERENCES_TABLE_HEADERS.DOCUMENT_NUMBER]: fieldsType.STRING,
  [DIGITAL_REFERENCES_TABLE_HEADERS.ISSUE_DATE]: fieldsType.DATE,
  [DIGITAL_REFERENCES_TABLE_HEADERS.PAYMENT_DUE_DATE]: fieldsType.DATE,
  [DIGITAL_REFERENCES_TABLE_HEADERS.NET_AMOUNT]: fieldsType.MONEY,
  [DIGITAL_REFERENCES_TABLE_HEADERS.TOTAL_AMOUNT]: fieldsType.MONEY,
  [DIGITAL_REFERENCES_TABLE_HEADERS.DEBIT_AMOUNT]: fieldsType.MONEY,
  [DIGITAL_REFERENCES_TABLE_HEADERS.CREDIT_AMOUNT]: fieldsType.MONEY,
  [DIGITAL_REFERENCES_TABLE_HEADERS.BALANCE]: fieldsType.MONEY,
  [DIGITAL_GENERAL_CHARGES_TABLE_HEADERS.NAME]: fieldsType.STRING,
  [DIGITAL_GENERAL_CHARGES_TABLE_HEADERS.AMOUNT]: fieldsType.MONEY,
};

export default {
  components: { Table },
  props: {
    document: { type: Object, required: true },
  },
  setup() {
    const { currencyLocaleStyle } = useCurrency();

    return { currencyLocaleStyle };
  },
  data() {
    return {
      isNil,
    };
  },
  computed: {
    itemColumns() {
      return Object.values(DIGITAL_ITEMS_TABLE_HEADERS)
        .filter((key) => this.document.structureParams.items[key])
        .map((key) => ({
          header: this.$t(`document.exports.schema.itemsFields.${key}`),
          customClass: 'p-1',
          key,
        }));
    },
    paymentDetailColumns() {
      return [
        {
          header: this.$t('document.exports.schema.fields.paymentDate'),
          key: DIGITAL_PAYMENT_DETAILS_TABLE_HEADERS.PAYMENT_DATE,
          width: '20%',
          customClass: 'p-1',
        },
        {
          header: this.$t('document.exports.schema.fields.paidAmount'),
          key: DIGITAL_PAYMENT_DETAILS_TABLE_HEADERS.PAID_AMOUNT,
          width: '20%',
          customClass: 'p-1',
        },
        {
          header: this.$t('document.exports.schema.fields.paymentMethod'),
          key: DIGITAL_PAYMENT_DETAILS_TABLE_HEADERS.PAYMENT_METHOD,
          width: '20%',
          customClass: 'p-1',
        },
        {
          header: this.$t('commons.details'),
          key: DIGITAL_PAYMENT_DETAILS_TABLE_HEADERS.DETAILS,
          width: '40%',
          customClass: 'p-1',
        },
      ];
    },
    referenceColumns() {
      return Object.values(DIGITAL_REFERENCES_TABLE_HEADERS)
        .filter((key) => this.document.structureParams.references[key])
        .map((key) => ({
          header: this.$t(`document.exports.schema.referencesFields.${key}`),
          customClass: 'p-1',
          key,
        }));
    },
    referenceTableRows() {
      return (
        this.document?.references.map((reference) => {
          const row = { ...reference };
          Object.values(DIGITAL_REFERENCES_TABLE_HEADERS).forEach((key) => {
            row[key] = this.formatByType(row[key], key);
          });
          return row;
        }) || []
      );
    },
    generalChargesColumns() {
      return Object.values(DIGITAL_GENERAL_CHARGES_TABLE_HEADERS)
        .filter((key) => this.document.structureParams.generalCharges[key])
        .map((key) => ({
          header: this.$t(`document.exports.schema.generalChargesFields.${key}`),
          customClass: 'p-1',
          key,
        }));
    },
    generalChargesTableRows() {
      return (
        this.document?.generalCharges.map((item) => {
          const row = { ...item };
          Object.values(DIGITAL_GENERAL_CHARGES_TABLE_HEADERS).forEach((key) => {
            row[key] = this.formatByType(row[key], key);
          });
          return row;
        }) || []
      );
    },
    paymentDetailRows() {
      return [
        {
          [DIGITAL_PAYMENT_DETAILS_TABLE_HEADERS.PAYMENT_DATE]: this.formatDate(this.document?.paymentDate) ?? '-',
          [DIGITAL_PAYMENT_DETAILS_TABLE_HEADERS.PAID_AMOUNT]: this.formatMoney(this.document?.paidAmount),
          [DIGITAL_PAYMENT_DETAILS_TABLE_HEADERS.PAYMENT_METHOD]: this.$t(
            `payment.exports.paymentMethods.fullName.${this.document?.paymentMethod?.type}`
          ),
          [DIGITAL_PAYMENT_DETAILS_TABLE_HEADERS.DETAILS]: this.transformPaymentDetails(this.document?.paymentMethod),
        },
      ];
    },
    itemsTableRows() {
      return (
        this.document?.items.map((item) => {
          const row = { ...item };
          Object.values(DIGITAL_ITEMS_TABLE_HEADERS).forEach((key) => {
            row[key] = this.formatByType(row[key], key);
          });
          return row;
        }) || []
      );
    },
    supplierName() {
      return this.document.supplier?.name;
    },
    structureParams() {
      return this.document.structureParams ?? {};
    },
  },
  methods: {
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, { ...options.short, timeZone: 'UTC' }) : '-';
    },
    formatMoney(number) {
      return typeof number === 'number' && !Number.isNaN(number)
        ? Number(number).toLocaleString(this.$i18n.locale, { ...this.currencyLocaleStyle, maximumFractionDigits: 5 })
        : '-';
    },
    formatPercent(number) {
      return typeof number === 'number' && !Number.isNaN(number)
        ? Number(number / 100).toLocaleString(this.$i18n.locale, percent)
        : '-';
    },
    formatByType(value, key) {
      const type = fieldsTypeMap[key];
      switch (type) {
        case fieldsType.MONEY:
          return this.formatMoney(value);
        case fieldsType.PERCENT:
          return this.formatPercent(value);
        case fieldsType.DATE:
          return this.formatDate(value);

        default:
          return value || '-';
      }
    },
    transformPaymentDetails(paymentMethod) {
      const details = reject(isEmpty)(this.getPaymentDetails(paymentMethod));

      return Object.keys(details)
        .map((d) => details[d])
        .join(', ');
    },
    getPaymentDetails(paymentMethod) {
      switch (paymentMethod?.type) {
        case PAYMENT_METHOD_TYPE.BANK_TRANSFER:
          return {
            bank: paymentMethod.bankTransfer?.bank
              ? constants.BANK_NUMBER_TO_BANK_NAME[paymentMethod.bankTransfer.bank]
              : '',
            branch: paymentMethod.bankTransfer?.branch
              ? `${this.$t('payment.paymentMethodModal.labels.branch')} ${paymentMethod.bankTransfer.branch}`
              : '',
            account: paymentMethod.bankTransfer?.account
              ? `${this.$t('payment.paymentMethodModal.labels.account')} ${paymentMethod.bankTransfer.account}`
              : '',
          };
        case PAYMENT_METHOD_TYPE.CREDIT_CARD:
          return {
            type: paymentMethod.creditCard?.type
              ? this.$t(`payment.paymentMethodModal.labels.${paymentMethod.creditCard.type}`)
              : '',
            number: paymentMethod.creditCard?.number ?? '',
          };
        case PAYMENT_METHOD_TYPE.CHEQUE:
          return {
            bank: paymentMethod.cheque?.bank ? constants.BANK_NUMBER_TO_BANK_NAME[paymentMethod.cheque.bank] : '',
            branch: paymentMethod.cheque?.branch
              ? `${this.$t('payment.paymentMethodModal.labels.branch')} ${paymentMethod.cheque.branch}`
              : '',
            number: paymentMethod.cheque?.number
              ? `${this.$t('payment.paymentMethodModal.labels.chequeNumber')} ${paymentMethod.cheque.number}`
              : '',
            account: paymentMethod.cheque.account
              ? `${this.$t('payment.paymentMethodModal.labels.account')} ${paymentMethod.cheque.account}`
              : '',
            dueDate: paymentMethod.cheque?.dueDate
              ? `${this.$t('payment.paymentMethodModal.labels.dueDate')} ${this.formatDate(
                  paymentMethod.cheque.dueDate
                )}`
              : '',
          };
        case PAYMENT_METHOD_TYPE.CASH:
        default:
          return {};
      }
    },
  },
};
</script>

<style scoped>
.direction-ltr {
  direction: ltr;
}
</style>
