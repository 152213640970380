import { useQuery, useResult } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

import { DOCUMENT_QUERY_NEW2, DOCUMENT_QUERY_NEW2_WITH_REPLICATES } from './queries';

export function useDocumentNew2(variables, options) {
  const { error } = useNotification();
  const { result, loading, onError } = useQuery(DOCUMENT_QUERY_NEW2, variables, options);
  const document = useResult(result);

  onError((err) => {
    console.error('documentModal - useDocumentNew2', err);
    error();
  });

  return {
    document,
    loading,
    onError,
  };
}

export function useDocumentNew2WithReplicates(variables, options) {
  const { error } = useNotification();
  const { result, loading, onError } = useQuery(DOCUMENT_QUERY_NEW2_WITH_REPLICATES, variables, options);
  const document = useResult(result);

  onError((err) => {
    console.error('documentModal - useDocumentNew2WithReplicates', err);
    error();
  });

  return {
    document,
    loading,
    onError,
  };
}
