<template>
  <div class="tag-container">
    <el-tooltip style="position" :disabled="!counter" :content="$t('document.modal.replicatesExist')" placement="top">
      <div>
        <span class="icon"><DocumentDuplicate height="24px" width="24px" /></span>
        <span class="round-tag" :class="$t('direction') === 'rtl' ? 'rtl' : 'ltr'">
          {{ counter }}
        </span>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
import { DocumentDuplicate } from '@/assets/icons';

export default {
  components: { DocumentDuplicate },
  props: {
    counter: { type: Number, required: true },
  },
};
</script>

<style lang="scss" scoped>
.tag-container {
  width: 24px;
  position: relative;
  display: inline-block;
  z-index: 1;
}

.icon {
  vertical-align: super;
  display: inline-block;
  position: relative;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  z-index: -1;
}

.round-tag {
  width: 16px;
  height: 16px;
  background-color: #3661dd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: white 2px solid;
  position: absolute;
  top: -5px;
  right: -6px;
  z-index: 1;
  padding: 2px 5px;
  font-family: Rubik;
  font-size: 9px;
  letter-spacing: 0.02em;
  text-align: center;
  color: white;
  line-height: 12px;
  font-weight: 400;

  &.ltr {
    right: 12px;
  }
}
</style>
