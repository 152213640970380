<template>
  <el-dialog
    v-if="documentId"
    :visible.sync="visibleSync"
    width="70%"
    append-to-body
    custom-class="document-modal rounded  mt-5 mb-0"
    :show-close="false"
    @close="handleClose"
  >
    <template #title>
      <div class="d-flex justify-content-between p-4 pb-0">
        <div>
          <template v-if="!loading">
            <ModalTitle
              v-if="document"
              :document-id="document.id"
              :title="!isUpload ? header : $t('document.modal.uploadHeader')"
              @on-document-change="changeReplicatedDocument"
            />
            <p>{{ supplierName }}</p>
          </template>
        </div>
        <Button type="icon" class="p-0" @click="handleClose">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div v-if="loading" v-loading="loading" class="h-100 d-flex justify-content-center" />
    <div v-else class="document-modal-body">
      <Tabs v-if="!isUpload" class="flex-1" :tabs="tabs" :active-tab.sync="activeTab" start-space="1rem" />
      <div class="document-modal-tab-content p-4 pb-0">
        <div v-show="activeTab === 0" class="h-100">
          <FileViewer v-if="document" :url="document.filePathUrl" />
          <div v-else>
            <h3>{{ $t('document.modal.documentFile.failedToLoad') }}</h3>
            <p>{{ $t('document.modal.documentFile.missingData') }}</p>
          </div>
        </div>
        <DigitalDocument v-if="activeTab === 1" class="overflow-auto" :document="document" />
      </div>
      <div class="p-4 d-flex justify-content-end">
        <slot name="header" />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { computed, ref, getCurrentInstance, watch } from 'vue';
import { FileViewer } from '@clarityo/ui-components';
import { Tabs, Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';
import { options } from '@/locale/dateConfig';

import { useDocumentNew2 } from './';
import DigitalDocument from './DigitalDocument';
import ModalTitle from './ModalTitle';

export default {
  components: { ModalTitle, FileViewer, DigitalDocument, Tabs, Button, CloseIcon },
  props: {
    documentId: { type: String, default: '' },
    visible: { type: Boolean, default: false },
    isUpload: { type: Boolean, default: false },
    isDigitalDocumentVisible: { type: Boolean, default: true },
  },
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;

    const selectedReplicatedDocumentId = ref(null);
    // reset selectedReplicatedDocumentId when document changed:
    watch(
      () => props.documentId,
      () => {
        selectedReplicatedDocumentId.value = null;
      },
      { immediate: true }
    );

    const visibleSync = computed({
      get: () => props.visible,
      set: (value) => emit('update:visible', value),
    });

    const formatDate = (ms) => {
      return ms ? new Date(ms).toLocaleDateString(root.$i18n.locale, { ...options.short, timeZone: 'UTC' }) : null;
    };

    const { document, loading } = useDocumentNew2(
      () => ({
        id: selectedReplicatedDocumentId.value || props.documentId,
      }),
      () => ({ enabled: !!props.documentId && props.visible })
    );

    return {
      changeReplicatedDocument: (documentId) => {
        selectedReplicatedDocumentId.value = documentId;
      },
      visibleSync,
      document,
      loading,
      activeTab: ref(0),
      supplierName: computed(() => document.value?.supplier?.name),
      tabs: computed(() => {
        const { type } = document.value ?? {};
        if (type === 'other' || !props.isDigitalDocumentVisible) {
          return [{ text: root.$t('document.modal.tab.file') }];
        }

        return [{ text: root.$t('document.modal.tab.file') }, { text: root.$t('document.modal.tab.digital') }];
      }),
      header: computed(() => {
        const { type, issueDate, documentNumber } = document.value ?? {};
        const typeTranslation = root.$i18n.t(`document.exports.schema.type.fullName.${type}`);
        if (type === 'other') {
          return typeTranslation;
        }

        const title = `${typeTranslation} ${documentNumber ?? ''}`;

        return root.$t('document.modal.header', { document: title, date: formatDate(issueDate) });
      }),
    };
  },
  methods: {
    handleClose() {
      this.$emit('close');
      this.visibleSync = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

$dialogHeight: 95vh;
$dialogHeaderHeight: 65px;
$dialogBodyHeight: calc(#{$dialogHeight} - #{$dialogHeaderHeight});

.document-modal-body {
  height: $dialogBodyHeight;

  .document-modal-tab-content {
    height: calc(#{$dialogBodyHeight} - 57px - 58px); //tabs hight
  }
}

::v-deep .el-dialog {
  height: $dialogHeight;

  .el-dialog__header {
    height: $dialogHeaderHeight;
    padding: 0;
  }
  .el-dialog__body {
    color: $typography-primary;
    height: $dialogBodyHeight;
    padding: 0;
  }
}
</style>
