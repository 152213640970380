import { gql } from '@apollo/client/core';

export const STRUCTURE_PARAMS_FIELDS = gql`
  fragment ItemsStructureParamsFields on StructureParamsItems {
    name
    sku
    gtin
    price {
      includingTax
    }
    netPrice
    quantity
    quantityInPackage
    packageQuantity
    discountRate
    discountAmount
    totalDiscount
    totalTax
    totalDeposits
    totalPackages
    totalAmount {
      includingTax
      includingDiscount
    }
    reference
  }

  fragment StructureParamsFields on StructureParams {
    documentNumber
    allocationNumber
    issueDate
    orderReference
    deliveryDate
    netAmount
    taxableAmount
    nonTaxableAmount
    taxRate
    taxAmount
    totalAmount
    amountDue
    discountRate
    discountAmount
    paymentDueDate
    paymentDate
    paidAmount
    paymentMethod
    rounding {
      debit
    }
    openingBalance
    referencesFromDate
    referencesToDate
    items {
      ...ItemsStructureParamsFields
    }
    references {
      documentNumber
      issueDate
      orderReference
      deliveryDate
      paymentDueDate
      netAmount
      totalAmount
      debitAmount
      creditAmount
      balance {
        debit
      }
      items {
        ...ItemsStructureParamsFields
      }
    }
    generalCharges {
      name
      amount
    }
  }
`;

export const ITEM_FIELDS = gql`
  fragment ItemFields on DocumentItem {
    sku
    gtin
    name
    quantity
    packageQuantity
    quantityInPackage
    price
    netPrice
    discountRate
    discountAmount
    totalDiscount
    totalAmount
    totalTax
    totalPackages
    totalDeposits
    productId
    reference
  }
`;

export const REFERENCE_FIELDS = gql`
  ${ITEM_FIELDS}
  fragment ReferenceFields on DocumentReference {
    documentNumber
    issueDate
    orderReference
    deliveryDate
    paymentDueDate
    netAmount
    totalAmount
    debitAmount
    creditAmount
    balance
    items {
      ...ItemFields
    }
  }
`;

export const REFERENCE_FIELDS_NEW2 = gql`
  ${ITEM_FIELDS}
  fragment ReferenceFields on DocumentReferenceNew2 {
    documentNumber
    issueDate
    orderReference
    deliveryDate
    paymentDueDate
    netAmount
    totalAmount
    debitAmount
    creditAmount
    balance
    items {
      ...ItemFields
    }
  }
`;

export const DOCUMENT_QUERY_NEW2 = gql`
  ${STRUCTURE_PARAMS_FIELDS}
  ${ITEM_FIELDS}
  ${REFERENCE_FIELDS_NEW2}
  query documentNew2($id: ID!) {
    documentNew2(id: $id) {
      id
      filePath
      filePathUrl
      businessId
      supplierId
      supplier {
        id
        name
      }
      issueDate
      type
      documentNumber
      allocationNumber
      isManual
      orderReference
      deliveryDate
      structureParams {
        ...StructureParamsFields
      }
      recorded
      discountRate
      discountAmount
      rounding
      netAmount
      taxableAmount
      nonTaxableAmount
      taxRate
      taxAmount
      totalAmount
      amountDue
      openingBalance
      paymentDueDate
      paidAmount
      paymentDate
      paymentMethod {
        type
        creditCard {
          type
          number
        }
        cheque {
          number
          bank
          branch
          account
          dueDate
        }
        bankTransfer {
          bank
          branch
          account
        }
      }
      generalCharges {
        name
        amount
      }
      items {
        ...ItemFields
      }
      references {
        ...ReferenceFields
      }
    }
  }
`;

export const DOCUMENT_QUERY_NEW2_WITH_REPLICATES = gql`
  query documentNew2($id: ID!) {
    documentNew2(id: $id) {
      replicates {
        id
        recordedAt
      }
      id
      recordedAt
    }
  }
`;
