<template>
  <div class="title-container" :class="hasReplications ? 'has-replicates' : ''">
    <span @click="triggerDropdownClick">
      <ReplicatesDocumentTag v-if="hasReplications" :counter="tagCounter" />
    </span>
    <el-dropdown
      ref="dropdownRef"
      trigger="click"
      :disabled="!hasReplications"
      @command="(documentId) => $emit('on-document-change', documentId)"
    >
      <div>
        <h2 class="d-inline title-text" :class="$t('direction') === 'rtl' ? 'rtl' : 'ltr'">
          {{ title }}
        </h2>
        <ChevronIcon v-if="hasReplications" class="chevron-down-icon" color="#71767D" />
      </div>
      <el-dropdown-menu slot="dropdown" class="dropdown-select-width" :disabled="!hasReplications">
        <el-dropdown-item
          v-for="replica in replicates"
          :key="replica.id"
          :disabled="replica.isCurrent"
          :command="replica.id"
        >
          {{ $t('document.modal.ModalTitle.documentRecordedAt') }}
          {{ formatDate(replica.recordedAt) }}
          {{ replica.isCurrent ? ' - ' + $t('document.modal.ModalTitle.currentDocument') : '' }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { ref } from 'vue';
import { options } from '@/locale/dateConfig';
import { ChevronIcon } from '@/assets/icons';
import ReplicatesDocumentTag from './ReplicatesDocumentTag';
import { useDocumentNew2WithReplicates } from './';

export default {
  components: {
    ChevronIcon,
    ReplicatesDocumentTag,
  },
  props: {
    documentId: { type: String, required: true },
    title: { type: String, required: true },
  },
  setup(props) {
    const dropdownRef = ref(null);
    const { document, loading } = useDocumentNew2WithReplicates(
      () => ({
        id: props.documentId,
      }),
      () => ({ enabled: !!props.documentId && props.visible })
    );

    return {
      document,
      loadingReplicates: loading,
      dropdownRef,
      triggerDropdownClick: () => {
        if (dropdownRef.value.visible) {
          dropdownRef.value.hide();
        } else {
          dropdownRef.value.show();
        }
      },
    };
  },
  computed: {
    tagCounter() {
      return 1 + (this.document?.replicates?.length || 0);
    },
    hasReplications() {
      return !this.loadingReplicates && !!this.document?.replicates?.length;
    },
    replicates() {
      if (!this.document) return [];
      const replicates = (this.document?.replicates || []).map((d) => ({
        id: d.id,
        recordedAt: d.recordedAt,
      }));
      replicates.push({
        id: this.document.id,
        recordedAt: this.document.recordedAt,
        isCurrent: true,
      });
      replicates.sort((a, b) => (a.recordedAt < b.recordedAt ? -1 : 1));
      return replicates;
    },
  },
  methods: {
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, options.twoDigits) : null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.title-container {
  height: 28px;

  &.has-replicates {
    cursor: pointer;

    .title-text {
      &.rtl {
        margin-right: 4px !important;
      }

      &.ltr {
        margin-left: 4px !important;
      }

      &:hover {
        text-decoration: underline !important;
      }
    }
  }
}

.chevron-down-icon {
  vertical-align: middle;
  transform: translateY(-4px);
}

.title-text {
  color: $typography-primary;
}
</style>
